import React from 'react'

import './custom-popup.styl'

function CustomPopup({ properties }) {
  const { Risk } = properties;

  return (
    <div className="custom_popup">
      <div className="custom_popup__header">
        <span>
          Risk level :
        </span>
        {/* <span>Theoretical flood depth : </span> */}
      </div>
      
      <div className="custom_popup__content">
        <span>{Risk}</span>
        {/* <span>5cm</span> */}
      </div>
    </div>
  )
}

export default CustomPopup