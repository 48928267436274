const setCurrentBaseMap = (currentBaseMap) => {
  return {
    type: 'SET_CURRENT_BASE_MAP',
    payload: currentBaseMap,
  }
}

const setStyleChangeIsDone = (styleChangeStatus) => {
  return {
    type: 'SET_STYLE_CHANGE_STATUS',
    payload: styleChangeStatus,
  }
}

export {
  setCurrentBaseMap,
  setStyleChangeIsDone,
}