import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './base-map-selector.styl';
import { setCurrentBaseMap } from './base-map-selector.redux.actions';

function BaseMapSelector() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  const clickHandler = (flag) => {
    const mapSelection = flag === 'satellite' ? 'satellite' : 'c58b0b9c-732f-4e58-9fc2-214d5ee5d598'
    dispatch(setCurrentBaseMap(mapSelection))
    setIsOpen(prevState => !prevState)
  }

  return (
    <div className="backgound-layer-selector__container">
      <div className={`base-map-selector__options__wrapper ${isOpen ? 'base-map-selector__options__wrapper--expanded' : 'base-map-selector__options__wrapper--closed'}`}>
        <button
          className="base-map-selector__options__satellite__button"
          type="button"
          onClick={() => clickHandler('satellite')}
        >
          <div className="base-map-selector__span__wrapper">
            <span>satellite</span>
          </div>
        </button>
        <button
          className="base-map-selector__options__street__button "
            onClick={() => clickHandler('street')}
          type="button"
        >
          <div className="base-map-selector__span__wrapper">
            <span>street</span>
          </div>
        </button>
      </div>
        
      <div>
        <button type="button" onClick={toggleIsOpen} className="background-layer-selector__button" />
      </div>

    </div>
  )
}

export default BaseMapSelector