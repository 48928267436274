// import { configureStore } from '@reduxjs/toolkit';
// import windowObserver from '@utils/WindowObserver/window-observer.slice';
// import cookies from '@utils/Cookies/cookies.slice';
// import maptilerMap from '@library/MaptilerMap/maptiler-map.redux.reducers';

// const createStoreArgs = [];

// if (import.meta.env.DEV) {
//   const { createLogger } = await import('redux-logger');
//   const logger = createLogger({
//     collapsed: (_getState, _action, logEntry) => !logEntry.error,
//   });

//   createStoreArgs.push(logger);
// }

// const store = configureStore({ 
//   reducer: {
//     windowObserver,
//     cookies,
//     maptilerMap,
//   },
//   middleware: getDefaultMiddleware => getDefaultMiddleware().concat(createStoreArgs),
// });

// export default store

import { applyMiddleware, combineReducers, createStore } from 'redux';
import maptilerMap from '@library/MaptilerMap/maptiler-map.redux.reducers'
import baseMapSelector from '@App/components/BaseMapSelector/base-map-selector.redux.reducers';

const reducers = combineReducers({ 
  maptilerMap,
  baseMapSelector,
});

const createStoreArgs = [reducers];

if (import.meta.env.DEV) {
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry.error,
  });

  createStoreArgs.push(applyMiddleware(logger));
}

export default createStore(...createStoreArgs);
