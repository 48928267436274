import BasePage from './pages/BasePage/BasePage';
import PrintPage from './pages/PrintPage/PrintPage';

const routes = [
  {
    name: 'Page Not Found', 
    path: '*', 
    element: <BasePage />,
  },
  {
    name: 'Page Not Found', 
    path: '/print', 
    element: <PrintPage />,
  },
];

export default routes;