const defaultState = {
  map: undefined,
  baseMap: '0958dd3c-eb74-437c-b551-ae1bc92c9db7',
}

const maptilerMap = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload,
      }
    case 'SET_BASE_MAP':
      return {
        ...state,
        baseMap: action.payload,
      }
    default:
      return state
  }
}

export default maptilerMap