import React, { useState } from 'react'
import MaptilerMap from '@library/MaptilerMap/MaptilerMap'

import './interactive-map.styl'; 
import BaseMapSelector from '@App/components/BaseMapSelector/BaseMapSelector';
import PitchController from '@App/components/PitchController/PitchController';

function InteractiveMap() {
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [mapResizeId, setMapResizeId] = useState()
  
  return (
    <div className="interactive-map">
      <MaptilerMap
        mapResizeId={mapResizeId}
        onMapInitialized={() => setIsMapLoading(false)}
      />
      <div className="interactive-map__backgound__layer__selector__wrapper">
          <BaseMapSelector />
      </div>
      <div className='interactive-map__backgound__pitchcontroller__wrapper'>
        <PitchController />
      </div>
    </div>
  )
}

export default InteractiveMap