import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@nazka/nazka.mapframe.components'

function PitchController() {
  const map = useSelector(state => state.maptilerMap.map);
  const [is3D, setIs3D] = useState(true)
  
  useEffect(() => {
    if (map) {
      map.on('pitch', () => {
        if (map.getPitch() === 0) {
          setIs3D(false)
        } else {
          setIs3D(true)
        }
      })
    }
  }, [map])

  return (
    <div className="pitch-controller">
      <Button
        css={{
          padding: '5px',
          borderRadius: '4px',
        }}
        color="white"
        onClick={() => {
          if (is3D) {
            map.setPitch(0)
          } else {
            map.setPitch(45)
          }
        }}
      >
        {is3D ? '2D' : '3D'}
      </Button>
    </div>
  )
}

export default PitchController