const controls = [
  {
    identifier: 'navigation',
    position: 'top-right',
    options: {
      showCompass: false,
      showZoom: true,
    },
  },
  // {
  //   identifier: 'geolocate',
  //   collapsed: true,
  //   position: 'top-right',
  //   options: {
  //     positionOptions: {
  //       enableHighAccuracy: true,
  //     },
  //     trackUserLocation: true,
  //   },
  // },
  {
    identifier: 'attribution',
    position: 'bottom-left',
    options: {
      compact: false,
      customAttribution: 'Powered by <a href="https://nazka.be" rel="noopener" target="_blank">Nazka Mapps</a>',
    },
  },
]

export {
  controls,
}