/* eslint-disable max-lines */
import React, { useEffect, useState, useRef } from 'react';
import * as maptilersdk from '@maptiler/sdk';
import queryString from 'query-string';
import * as turf from '@turf/turf';
import axios from 'axios';
import '@maptiler/sdk/dist/maptiler-sdk.css';

maptilersdk.config.apiKey = import.meta.env.VITE_STATICMAP_MAPTILER_TOKEN;

function PrintPage() {
  const [geojson, setGeojson] = useState(null);
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    const { fileId } = queryString.parse(window.location.search);
    if (!fileId) return console.error('File ID is missing');

    return axios.get(`api/temp/${fileId}`)
      .then(response => setGeojson(response.data))
      .catch(error => console.error('Error fetching GeoJSON:', error));
  }, []);

  // Initialize Map
  useEffect(() => {
    if (!geojson || mapInstance.current) return;

    const center = geojson.features[0].geometry.type === 'Point'
      ? geojson.features[0].geometry.coordinates
      : turf.centroid(geojson).geometry.coordinates;

    mapInstance.current = new maptilersdk.Map({
      container: mapRef.current,
      style: 'c58b0b9c-732f-4e58-9fc2-214d5ee5d598',
      center,
      zoom: 15,
      fadeDuration: 0,
      preserveDrawingBuffer: true, 
      antialias: false, 
      interactive: false,
      attributionControl: false,
      navigationControl: false,
      geolocateControl: false,
      pixelRatio: 1,
      trackResize: false,
      renderWorldCopies: false,
      localIdeographFontFamily: false,
      bearing: 0,
      pitch: 0,
    });

    mapInstance.current.on('load', () => {
      addLayers(mapInstance.current, geojson);
      document.body.classList.add('map-ready');
    });
  }, [geojson]);

  // Add Layers
  function addLayers(map, geojsonData) {
    if (geojsonData.features[0].geometry.type === 'Point') {
      new maptilersdk.Marker({ color: '#7030A0' })
        .setLngLat(geojsonData.features[0].geometry.coordinates)
        .addTo(map);
      document.body.classList.add('map-ready');
    } else {
      map.addSource('damage', { type: 'geojson', data: geojsonData });

      map.addLayer({
        id: 'damage',
        type: 'fill',
        source: 'damage',
        paint: {
          'fill-color': [
            'match',
            ['get', 'Risk'],
            'H', '#ce0000',
            'M', '#ffae00',
            'L', '#008000',
            'W', '#0000FF',
            '#CCCCCC',
          ],
          'fill-opacity': 0.4,
        },
      });
      document.body.classList.add('map-ready');

      map.addLayer({
        id: 'boundary',
        type: 'line',
        source: 'damage',
        paint: { 'line-opacity': 0.4, 'line-color': 'white' },
      });

      map.once('idle', () => {
        if (!document.body.classList.contains('map-ready')) {
          document.body.classList.add('map-ready');
        }
      });
    }
  }

  return <div ref={mapRef} className="maptiler-map" id="map" />;
}

export default PrintPage;
