const setMap = (map) => {
  return {
    type: 'SET_MAP',
    payload: map,
  }
}

const setDamageLayer = (layer) => {
  return {
    type: 'SET_DAMAGE_LAYER',
    payload: layer,
  }
}

export {
  setMap,
  setDamageLayer,
}