import * as maptilersdk from '@maptiler/sdk';
import * as turf from '@turf/turf';

import sources from './style/style.sources.json';
import layers from './style/style.layers.json';

maptilersdk.config.apiKey = import.meta.env.VITE_MAPTILER_TOKEN;

const addImportantLayers = (map) => {
  try {
    for (let i = 0; i < 10; i += 1) {
      map.addLayer({
        id: `z-index-${i}`,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
        paint: {},
      });
    }
  
    ['River', 'Water', 'River labels', 'Bridge'].forEach((id) => {
      map.moveLayer(id, 'z-index-2');
    });
  } catch (error) {
    console.error(error)
  }
}

const removeImportantLayers = (map) => {
  const layerss = [...map.getStyle().layers]
  layerss.forEach(layer => map.removeLayer(layer))
}

/*  Exports
    ======================================================================================= */
function addSourcesAndLayersFromConfigToMap(mapboxMap) {
  // Object.keys(sources).forEach(sourceId => mapboxMap.addSource(sourceId, sources[sourceId]));
  // layers.forEach(layer => mapboxMap.addLayer(layer));

  addImportantLayers(mapboxMap)
  // mapboxMap.dragPan.disable();

  return mapboxMap;
}

function mapFactory(mapEl, center, baseMap) {
  const radius = 2
  const circle = turf.circle(center, radius, { steps: 10, units: 'kilometers' });
  const bbox = turf.bbox(circle)

  const options = { 
    container: mapEl,
    style: baseMap,
    center,
    geolocateControl: false,
    navigationControl: false,
    attributionControl: false,
    pitch: 45,
    minZoom: 15,
    maxBounds: [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
  };

  return new maptilersdk.Map(options);
}

export {
  mapFactory,
  addSourcesAndLayersFromConfigToMap,
  addImportantLayers,
  removeImportantLayers,
}