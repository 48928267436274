const defaultState = {
  styleChangeStatus: true,
  currentBaseMap: 'c58b0b9c-732f-4e58-9fc2-214d5ee5d598',
};

function baseMapSelector(state = defaultState, action) {
  switch (action.type) {
    case 'SET_STYLE_CHANGE_STATUS':
      return { 
        ...state, 
        styleChangeStatus: action.payload,
      }
    case 'SET_CURRENT_BASE_MAP': 
      return {
        ...state, 
        currentBaseMap: action.payload,
      }
    default:
      return state
  }
}

export default baseMapSelector