import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrev } from '@services/hooks.service';
import Controls from './controls/Controls/Controls';
import { mapFactory, addSourcesAndLayersFromConfigToMap, removeImportantLayers, addImportantLayers } from './maptiler-map.factory';
import { resizeMap } from './maptiler-map.utils';
import { setMap as setMapInRedux } from './maptiler-map.redux.actions';
import * as maptilersdk from '@maptiler/sdk';
import CustomPopup from './controls/Controls/CustomControls/CustomPopup/CustomPopup';
import { createRoot } from 'react-dom/client';
import * as turf from '@turf/turf';

import '@maptiler/sdk/dist/maptiler-sdk.css';
import './maptiler-map.styl';
import axios from 'axios';

function MaptilerMap(props) {
  const dispatch = useDispatch();
  const [map, setMap] = useState(null)
  const { mapResizeId, onMapInitialized } = props
  const mapRef = useRef(null);
  const prevMapResizeId = usePrev(mapResizeId)
  const prevMap = usePrev(map)
  const queryParams = new URLSearchParams(window.location.search);
  const geojsonId = queryParams.get('geojson_id');
  const currentBaseMap = useSelector(state => state.baseMapSelector.currentBaseMap)
  const [newgeojson, setNewGeojson] = useState()

  // useEffect(() => {
  //   setMap(mapFactory(mapRef.current, center, currentBaseMap));
  // }, []);


  useEffect(() => {
    if (map) { 
      removeImportantLayers(map)
      map.setStyle(currentBaseMap)
      map.once('styledata', () => {
        addImportantLayers(map)
        populateLayer()
      })
    }
  }, [currentBaseMap])

  useEffect(() => {
    if (map && (map !== prevMap)) {
      map.on('load', () => {
        addSourcesAndLayersFromConfigToMap(map)
        dispatch(setMapInRedux(map))
        populateLayer()
      });
      onMapInitialized();
    }
  }, [map, onMapInitialized, prevMap]);

  useEffect(() => {
   getgeojson()
  }, [])
  
  const getgeojson = async () => {
    const { data } = await axios.get(`api/getgeojson/${geojsonId}`)
    const center = data.features[0].geometry.type === 'Point'
          ? data.features[0].geometry.coordinates
          : turf.centroid(data).geometry.coordinates;
    setMap(mapFactory(mapRef.current, center, currentBaseMap));
    setNewGeojson(data)
  }

  const populateLayer = () => { 
    if (newgeojson.features[0].geometry.type === 'Point') {
      new maptilersdk.Marker({ color: '#7030A0' })
        .setLngLat(newgeojson.features[0].geometry.coordinates)
        .addTo(map);
    } else {
      if (!map.getSource('damage')) {
          map.addSource('damage', {
            type: 'geojson',
            data: newgeojson,
          });
        } else {
          map.getSource('damage').setData(newgeojson);
        }
  const layerA = map.querySourceFeatures('damage');
  const layerB = map.querySourceFeatures('Water');

  layerA.forEach((featureA) => {
    layerB.forEach((featureB) => {
      if (turf.intersect(featureA, featureB)) {
        map.setFeatureState(
          { source: 'damage', id: featureA.id },
          { isOverlapping: true }
        );
      }
    });
  });
      
      map.addLayer({
        id: 'damage',
        type: 'fill-extrusion',
        source: 'damage',
        paint: {
          'fill-extrusion-color': [
            'match',
            ['get', 'Risk'],
            'H',
            '#ce0000',
            'M',
            '#ffae00',
            'L',
            '#008000',
            'W',
            '#0000FF',
            '#CCCCCC',
          ],
          'fill-extrusion-height': ['get', 'height'],
          'fill-extrusion-opacity': [
            'interpolate',
            [
              'linear',
            ],
            [
              'zoom',
            ],
            11,
            0.6,
            15,
            0.3,
            18,
            0.2,
          ],
          // 'fill-color': '#98b',
          // 'fill-opacity': 0.8,
        },
        filter: [
          '==',
          [
            'geometry-type',
          ],
          'Polygon',
        ],
      }, 'z-index-1');
      map.addLayer({
        id: 'border',
        type: 'line',
        source: 'damage',
        layout: {
          visibility: 'visible',
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-opacity': 0.8,
          'line-color': 'hsla(0, 0%, 100%, 0.5)',
          'line-blur': 0,
          'line-gap-width': 0,
        },
        filter: [
          '==',
          [
            'geometry-type',
          ],
          'LineString',
        ],
      });
      map.addLayer({
        id: 'boundary',
        type: 'line',
        source: 'damage',
        layout: {
          visibility: 'visible',
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-opacity': 0.8,
          'line-color': 'hsla(0, 0%, 100%, 0.5)',
          'line-blur': 0,
          'line-gap-width': 0,
        },
        filter: [
          '==',
          [
            'geometry-type',
          ],
          'Polygon',
        ],
      });
    }
        
        
         const popup = new maptilersdk.Popup({
           closeButton: false,
           closeOnClick: false,
         });
       
         const popupEl = document.createElement('div');
         const root = createRoot(popupEl)    
        map.on('mousemove', 'damage', (e) => {
          const upperLayerFeatures = map.queryRenderedFeatures(e.point, { layers: ['Water'] });
          if (upperLayerFeatures.length > 0) {
              popup.remove();
              map.getCanvas().style.cursor = '';
              return;
          }

           map.getCanvas().style.cursor = 'pointer';
           const { properties } = e.features[0];
       
           root.render(<CustomPopup properties={properties} />)
       
           popup.setLngLat(e.lngLat).setDOMContent(popupEl).addTo(map);
         });
       
         map.on('mouseleave', 'damage', () => {
           map.getCanvas().style.cursor = '';
           popup.remove();
         });
  }

  useEffect(() => {
    if ((mapResizeId !== prevMapResizeId) && map) {
      resizeMap(map);
    }
  }, [map, mapResizeId, prevMapResizeId]);

  return (
    <div ref={mapRef} className="maptiler-map" id='map'>
      <Controls map={map} />
    </div>
  );
}

export default MaptilerMap;